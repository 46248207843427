import "../App.css";
import { useEffect, useState } from "react";
import LogoWhatsapp from "../assets/whatsapp.png";
import LogoFacebook from "../assets/facebook.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NormasLegales = () => {
  const [data, setData] = useState("");
  const date = new Date().toLocaleDateString('en-GB');

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await fetch("https://normas-app.herokuapp.com/");
    const data = await response.json();
    setData(data?.data);
  };

  const shareWhatsapp = async (item) => {
    const message = `*${item?.title.replace(
      " ",
      "%20"
    )}*%0A${item?.ley_title.replace(" ", "%20")}%0A${item?.description.replace(
      " ",
      "%20"
    )}%0A*Link:*%20${item?.ley} `;
    window.open(
      `https://api.whatsapp.com/send?text=${message}`.toString(),
      "_blank"
    );
  };

  const shareFacebook = async (item) => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${item?.ley}&quote=${item?.title}%0A%0A${item?.description}`,
      "_blank"
    );
  };

  return (
    <div className="App">
      <div className="main_content">
        <h1 className="mt-5 fw-bold" style={{ color: "#003E72" }}>
          Normas Legales
        </h1>
        <h4 className="fw-bold" style={{ color: "#003E72" }}>
          {date}
        </h4>

        {data ? (
          data.map((item, index) => (
            <div
              className="card mb-3 mx-auto my-5"
              style={{ maxWidth: "700px" }}
              key={index}
            >
              <div className="row g-0 align-items-center">
                <div className="col-md-4">
                  <img
                    src={item.image}
                    className=" rounded-start"
                    alt=""
                    width="200px"
                    height="270px"
                  />
                </div>
                <div className="col-md-8 text-start">
                  <div className="card-body">
                    <h5 className="card-title fw-bold">{item?.title}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      <a href={item?.ley} target="_blank" rel="noreferrer">
                        {item?.ley_title}
                      </a>
                    </h6>
                    <p className="card-text">{item?.description}</p>
                    <p className="card-text">
                      <small className="text-muted">{item?.date}</small>
                    </p>
                    <div className="d-flex align-items-center">
                      <a href={item?.download} target="_blank" rel="noreferrer">
                        <button className="btn btn-primary text-start">
                          Descargar
                        </button>
                      </a>
                      <div className="mx-2">
                        <button
                          onClick={() => shareWhatsapp(item)}
                          style={{ border: 0, background: "transparent" }}
                        >
                          <img
                            src={LogoWhatsapp}
                            width="30"
                            height="30"
                            alt=""
                          />
                        </button>
                        <button
                          onClick={() => shareFacebook(item)}
                          style={{ border: 0, background: "transparent" }}
                          className="mx-1"
                        >
                          <img
                            src={LogoFacebook}
                            width="30"
                            height="30"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default NormasLegales;
