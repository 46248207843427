import Logo from "../logo.png";
import { useNavigate } from "react-router";

const NavbarComponent = () => {
  const navigate = useNavigate();

  const goTo = (url) => {
    navigate(url);
  };
  return (
    <nav
      className="navbar navbar-light navbar-expand-lg bg-light"
      style={{
        boxShadow: "-1px 6px 11px -4px rgba(0,0,0,0.7)",
      }}
    >
      <div className="container-fluid py-2 px-5">
        <img src={Logo} alt="" width="110" height="65" />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse align-items-center ml-100"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav  mb-2 mb-lg-0 d-flex justify-content-between align-items-center mx-0">
            <li className="nav-item mx-4 py-2 display-lg-none text-center">
              <h5
                className="nav-link active my-0 fw-bold"
                onClick={() => goTo("/")}
                style={{ cursor: "pointer", color: "#003E72" }}
              >
                Normas Legales
              </h5>
            </li>
            <li
              className="nav-item mx-4 py-2 pb-0 display-lg-none text-center"
              onClick={() => goTo("/opiniones")}
            >
              <h5
                className="nav-link active my-0 fw-bold"
                style={{ cursor: "pointer", color: "#003E72" }}
              >
                Opiniones de la Dirección Técnico Normativa
              </h5>
              <p
                className="mt-0 fw-bold"
                style={{ cursor: "pointer", color: "#003E72" }}
              >
                OSCE
              </p>
            </li>
            <li
              className="nav-item mx-4 py-2 pb-0 display-lg-none text-center"
              onClick={() => goTo("/resoluciones")}
            >
              <h5
                className="nav-link active my-0 fw-bold"
                style={{ cursor: "pointer", color: "#003E72" }}
              >
                Resoluciones del Tribunal de Contrataciones del Estado
              </h5>
              <p
                className="mt-0 fw-bold"
                style={{ cursor: "pointer", color: "#003E72" }}
              >
                OSCE
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
