import React from "react";
import { useEffect, useState } from "react";
import LogoWhatsapp from "../assets/whatsapp.png";
import LogoFacebook from "../assets/facebook.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Resoluciones = () => {
  const [data, setData] = useState("");
  const [numData, setNumData] = useState(20);
  const date = new Date().toLocaleDateString('en-GB');

  useEffect(() => {
    getData(numData);
  }, [numData]);

  const getData = async (num) => {
    setData(null);
    const response = await fetch(
      `https://normas-app.herokuapp.com/resolutions?max=${num}`
    );
    const data = await response.json();
    setData(data?.data);
  };

  const shareWhatsapp = async (item) => {
    const message = `*${item?.title.replace(
      " ",
      "%20"
    )}*%0A${item?.date.replace(" ", "%20")}%0A${item?.description.replace(
      " ",
      "%20"
    )}%0A*Link:*%20${item?.link} `;
    window.open(
      `https://api.whatsapp.com/send?text=${message}`.toString(),
      "_blank"
    );
  };

  const shareFacebook = async (item) => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?href=${item?.title}&quote=${item?.description}%0A%0A${item?.date}`,
      "_blank"
    );
  };

  const updateOpinions = (e) => {
    setNumData(e.target.value);
  };

  return (
    <div className="App">
      <div className="main_content">
        <h1 className="mt-5 fw-bold mb-3" style={{ color: "#003E72" }}>
          Resoluciones del Tribunal de Contrataciones del Estado
        </h1>
        <h1 className="fw-bold mb-3" style={{ color: "#003E72" }}>
          OSCE
        </h1>
        <h4 className="fw-bold mt-4" style={{ color: "#003E72" }}>
          {date}
        </h4>

        <div className="d-flex align-items-center justify-content-center mx-auto my-5">
          <p className="my-0">Traer las últimas</p>
          <select
            className="form-select mx-2"
            style={{ width: "auto" }}
            value={numData}
            onChange={updateOpinions}
          >
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </select>
          <p className="my-0">resoluciones.</p>
        </div>
        {data ? (
          data.map((item, index) => (
            <div
              className="card mb-3 mx-auto my-5"
              style={{ maxWidth: "600px" }}
              key={index}
            >
              <div className="row g-0 align-items-center py-3">
                <div className="col-md-12 text-start">
                  <div className="card-body w-100">
                    <a
                      className="card-title fw-bold"
                      href={item?.link}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {item?.title}
                    </a>
                    <p className="card-text">
                      <small className="text-muted">{item?.date}</small>
                    </p>
                    <p className="card-text">{item?.description}</p>

                    <div className="d-flex align-items-centermy-3">
                      <a href={item?.download} target="_blank" rel="noreferrer">
                        <button className="btn btn-primary text-start">
                          Descargar
                        </button>
                      </a>
                      <div className="mx-2">
                        <button
                          onClick={() => shareWhatsapp(item)}
                          style={{ border: 0, background: "transparent" }}
                        >
                          <img
                            src={LogoWhatsapp}
                            width="30"
                            height="30"
                            alt=""
                          />
                        </button>
                        <button
                          onClick={() => shareFacebook(item)}
                          style={{ border: 0, background: "transparent" }}
                          className="mx-1"
                        >
                          <img
                            src={LogoFacebook}
                            width="30"
                            height="30"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Resoluciones;
